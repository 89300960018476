import { useLocation } from '@reach/router';
import { DEFAULT_LANGUAGE, ENABLE_MULTI_LANGUAGE } from '../constants/features-flag';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

const AVAILABLE_LANGUAGE = ['id', 'en'];

/**
 * Custom hook to return current lang code
 * @return {string}
 */
function useLang() {
  if (!ENABLE_MULTI_LANGUAGE) return DEFAULT_LANGUAGE;

  const location = useLocation();
  const lang = getQueryValue(location, 'lang');

  // only return language-code if that code available in our dictionary
  if (AVAILABLE_LANGUAGE.includes(lang)) {
    return lang;
  }

  return DEFAULT_LANGUAGE;
}

export default useLang;
